<template>
    <div>
        <loading-state
                v-if="loading"

                title="Loading dates">
        </loading-state>
        <div class="container">
            <div class="d-flex" v-if="dateError">
                <p>There has been a problem loading the available dates for this booking, we have tried the next 4 weeks but have no availability. Please contact your SSM or customer services to get this revisit booked in.</p>
            </div>

            <label>Select a revisit repair date</label>
            <ul class="list-group selection-list mb-4">
                <li class="list-group-item" v-for="date in dates" :key="date.date">
                    <label class="custom-control custom-checkbox mb-0">
                        <input type="checkbox" class="custom-control-input"
                               :id="date.date"
                               v-model="date.date_selected" @change="selectDate(date)">
                        <label class="custom-control-label mb-0" :for="date.date">{{date.week_day}} {{date.full_date}}</label>
                    </label>
                </li>
            </ul>

            <div class="d-flex">
                <button v-if="showLoadMore" class="btn btn-solid-secondary btn-rounded btn-sm mx-auto px-3" @click="loadMoreDates" :disabled="loadingMore">
                    <template v-if="!loadingMore">Load More Dates</template>
                    <template v-if="loadingMore"><animated-loading class="mr-2" size="xs"></animated-loading> Loading</template>
                </button>
            </div>

            <!-- Footer -->
            <fixed-footer
                    :class="{'active': dateSelected}"
                    :nextLabel="`Next`"
                    :nextRoute="`revisit.confirm-revisit`"
                    :prevRoute="`revisit.update-address`">
            </fixed-footer>
        </div>
    </div>
</template>

<script>
    export default {
        name: "RepairDate",

        data() {
            return {
                currentDate: null,
                dates: [],
                dateSelected: false,
                loading: true,
                loadingMore: false,
                showLoadMore: false,
                dateError: false,
                loadCount: 0
            }
        },

        mounted() {
            this.loadDates();
        },

        methods: {
            loadDates() {
                this.currentDate = new Date().toISOString().slice(0,10);

                this.availableDatesForDate();
            },

            availableDatesForDate() {
                this.loadCount++;

                this.$http.get(`/api/v4/warranty/booking/${localStorage.revisitBookingId}/load-days/${this.currentDate}/7`)
                    .then(res => {
                        this.loading = false;
                        this.loadingMore = false;

                        this.dates = [];

                        res.data.data.forEach(date => {
                            if (date.available) {
                                date.date_selected = false;
                                this.dates.push(date);
                            }
                        });

                        this.hasAvailableDate();
                    })
            },

            hasAvailableDate() {
                let dateAvailable = false;
                this.dates.forEach(date => {
                    if (date.available) {
                        dateAvailable = true;
                    }
                });

                if (dateAvailable) {
                    this.showLoadMore = true;
                    return;
                }

                if (this.loadCount === 4) {
                    this.dateError = true;
                    return;
                }

                let incrementedDate = new Date(this.currentDate);
                incrementedDate.setDate(incrementedDate.getDate() + 7);
                this.currentDate = incrementedDate.toISOString().slice(0,10);

                this.availableDatesForDate();
            },

            selectDate(date) {
                const dates = this.dates;
                dates.forEach(eachDate => {
                    eachDate.date_selected = eachDate.date === date.date;
                });

                this.dates = dates;

                this.$http.post(`/api/v4/warranty/booking/${localStorage.revisitBookingId}/date`, date)
                    .then(() => {
                        this.dateSelected = true;
                    });
            },

            loadMoreDates() {
                let incrementedDate = new Date(this.currentDate);
                incrementedDate.setDate(incrementedDate.getDate() + 7);
                this.currentDate = incrementedDate.toISOString().slice(0,10);
                this.loadingMore = true;
                this.availableDatesForDate();
            }
        }
    }
</script>

<style scoped>

</style>
